import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import { AlertDialogProvider } from '../../components/src/AlertBoxContext';
import { ToastProvider } from '../../components/src/ToastContext';
import { DialogProvider } from '../../components/src/DialogContext';

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    {/* <ThemeProvider theme={theme}> */}
    <Router>
      <>
        <CssBaseline />
        <AlertDialogProvider>
          <DialogProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </DialogProvider>
        </AlertDialogProvider>
      </>
    </Router>
    {/* </ThemeProvider> */}
  </Suspense>,
  document.getElementById('root')
);
registerServiceWorker();
