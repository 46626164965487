import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";

import {
  withStyles,
  createStyles,
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  List,
  ListItem,
  ListItemText,
  InputBase,
  Select,
  Menu,
  MenuItem,
  InputLabel,
  TextField,
  TablePagination,
  Hidden,
} from "@material-ui/core";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Customizable Area End

import PublishersListController, {
  Props,
  configJSON,
} from "./PublishersListController";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";

import {
  searchImage,
  threeDot,
  EditIcon,
  BlockIcon,
  DeleteIcon,
} from "./assets";

class PublishersList extends PublishersListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = (): any => {
    if (this.props.authToken) {
      this.getPublishersList();
    }
  };
  componentDidUpdate(prevProps: any) {
    if (prevProps.authToken !== this.props.authToken) {
      this.getPublishersList();
    }
  }
  handleRoutes = (route: any) => {
    this.props.history.push({
      pathname: route,
      state: { publisher: this.state.publishersList[this.state.selectedIndex] },
    });
  };
  handleClick = (event: any, value: any) => {
    this.setState({ selectedIndex: value, anchorEl: event.currentTarget });
  };

  handleClose = (value: any) => {
    if (value === "edit") {
      // this.setState({ selectedIndex: null, anchorEl: null });
      this.handleRoutes("/AdminConsole/EditPublisher");
    } else if (value === "block") {
      this.blockPublisher();
    } else if (value === "delete") {
      this.deletePublisher();
    } else {
      this.setState({ selectedIndex: null, anchorEl: null });
    }
  };
  handleChangePage = (e: any, newPage: number) => {
    this.setState({ page: newPage });
  };
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    return (
      <Box m={3} className={classes.root}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={2}>
              <div
                style={{
                  height: "100vh",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "0.3rem",
                }}
              >
                <Box m={1} textAlign="center">
                  <Typography variant="h6">
                    John Mike <br /> Admin
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      <ListItemText
                        primary="Upload Media"
                        onClick={() => this.handleRoutes("/AdminConsole")}
                      />
                    </ListItem>
                    <ListItem button>
                      {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                      <ListItemText
                        primary="Students"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Students")
                        }
                      />
                    </ListItem>
                    <ListItem button selected={true}>
                      <ListItemText
                        primary="Publishers"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Publishers")
                        }
                      />
                    </ListItem>

                    <ListItem button>
                      <ListItemText
                        primary="All Books"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/AllBooks")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Notes"
                        onClick={() => this.handleRoutes("/AdminConsole/Notes")}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Subscriptions"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Subscriptions")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Logout"
                        onClick={this.props.logout}
                      />
                    </ListItem>
                  </List>
                </Box>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={10}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/AdminConsole">
                Home
              </Link>
              <Typography color="textPrimary">publishers</Typography>
            </Breadcrumbs>
            <Box mt={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={8}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <img src={searchImage} width="25px" alt="search" />
                    </div>
                    <InputBase
                      placeholder="Search Publishers"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      fullWidth
                      inputProps={{ "aria-label": "search" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      this.handleRoutes("/AdminConsole/AddPublisher")
                    }
                  >
                    ADD PUBLISHERS
                  </Button>
                </Grid>
              </Grid>
              <Box display="flex" mb={3} />
              <Typography variant="h5">All Publisher</Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                    <TableRow>
                      <TableCell className={classes.th}>USER</TableCell>
                      <TableCell className={classes.th} align="right">
                        BOOKS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        DOWNLOADS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        VIEWERS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        REVIEWS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        EARNINIG
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        STATUS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.publishersList
                      .slice(this.state.page * 10, (this.state.page + 1) * 10)
                      .map((row: any, i: any) => (
                        <TableRow key={row.email}>
                          <TableCell className={classes.th}>
                            {row.name}
                            <br />
                            {row.email}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.totalbooks}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.totaldownloads}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.totalviews}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.totalviews}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.totalearnings}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            {row.status === "active" ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.active}
                              >
                                Active
                              </Button>
                            ) : row.status === "suspended" ? (
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className={classes.blocked}
                              >
                                Suspended
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className={classes.blocked}
                              >
                                Blocked
                              </Button>
                            )}
                          </TableCell>
                          <TableCell className={classes.th} align="right">
                            <Button
                              aria-controls="selectActionButton"
                              aria-haspopup="true"
                              onClick={(e) => this.handleClick(e, i)}
                            >
                              <img
                                src={threeDot}
                                height="25px"
                                alt="three dot"
                              />
                            </Button>
                            <Menu
                              id="selectActionButton"
                              keepMounted
                              open={i === this.state.selectedIndex}
                              onClose={this.handleClose}
                              anchorEl={this.state.anchorEl}
                            >
                              <MenuItem
                                onClick={() => this.handleClose("edit")}
                              >
                                <Box mr={1}>
                                  <img src={EditIcon} width="18px" alt="icon" />
                                </Box>
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => this.handleClose("block")}
                              >
                                <Box mr={1}>
                                  <img
                                    src={BlockIcon}
                                    width="20px"
                                    alt="icon"
                                  />
                                </Box>
                                Block
                              </MenuItem>
                              <MenuItem
                                onClick={() => this.handleClose("delete")}
                              >
                                <Box mr={1}>
                                  <img
                                    src={DeleteIcon}
                                    width="15px"
                                    alt="icon"
                                  />
                                </Box>
                                Delete
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  style={{
                    width: "100%",
                    overflow: "inherit",
                  }}
                  component="div"
                  count={this.state.publishersList.length}
                  page={this.state.page}
                  rowsPerPage={10}
                  labelRowsPerPage=""
                  onPageChange={this.handleChangePage}
                  rowsPerPageOptions={[]}
                />
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Customizable Area Start

const PublishersListAlertBox = withAlertBox(PublishersList);
const PublishersListLoader = withLoader(PublishersListAlertBox);
const PublishersListToast = withToast(PublishersListLoader);
const PublishersListWithDialog = withDialog(PublishersListToast);

// Customizable Area End
export default withStyles((theme) =>
  createStyles({
    root: {},
    uploadContainer: {
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
      textAlign: "center",
      padding: "2rem",
      marginRight: "2rem",
      marginTop: "1rem",
    },
    StatisticsContainer: {
      borderRadius: "1rem",
      marginTop: "1rem",
    },
    StatisticsItems: {
      border: "1px solid #f1f1f1",
      padding: "1.6rem",
    },
    earningContainer: {
      borderRadius: "1rem",
      marginTop: "1rem",
    },
    earningItems: {
      border: "1px solid #f1f1f1",
      padding: "1.6rem",
    },
    earningTitle: {
      margin: "1rem",
      padding: "1rem",
      backgroundColor: "#f1f1f1",
      borderRadius: "0.5rem",
    },
    th: {
      fontSize: "0.775rem",
    },
    blocked: {
      borderRadius: "1rem",
      backgroundColor: "#f5440017",
      fontSize: "12px",
    },
    active: {
      borderRadius: "1rem",
      backgroundColor: "#c0caff8c",
      fontSize: "12px",
      color: "#0127ff",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#f1f1f1",
      marginLeft: 0,
      width: "100%",
      border: "1px solid black",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    input: {
      border: "none",
    },
  })
)(PublishersListWithDialog);
