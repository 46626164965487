import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Divider from "@material-ui/core/Divider";
import HomeController, { Props, S } from "./HomeController.web";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import SideBar from "./Sidebar.web";
import AppHeader from "../../../components/src/Header/index";
import BookListing from "../../../components/src/BookListing/index";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import Link from "@material-ui/core/Link";

import "../assets/css/Instore.css";
import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
} from "./assets";
//import styles from '../assets/css/index'

const styles = {
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },

  search: {
    position: "relative",
    background: "#5196DD",
    // border:"1px solid red",
    // backgroundCo #4CAEEF
    color: "white",
    //   backgroundColor:"rgb(68, 156, 214)",
    //   marginLeft:"0",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    width: "100%",
    padding: "13px 50px",
  },
  // notesForSale: {
  //   backgroundColor: "#F2F3F2",
  //   height: "auto",
  //   margin: "20px",
  //   borderRadius: "8px",
  // },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    // margin: "23px",

    minWidth: 120,
  },
} as any;
class viewAllBooks extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <AppHeader />
        <Loader loading={this.state.Loader} />
        <div className="view-book-page catalog-home">
          <SideBar selectedIndex={10} className="col-2" />
          <div className="content">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link href="/Catalogue/Home" style={{ color: "#4caeef" }}>
                Home
              </Link>
              <Link href="/Catalogue/Home" style={{ color: "#4caeef" }}>
                Search Books
              </Link>
              <Typography color="textPrimary">In Store</Typography>s
            </Breadcrumbs>
            <h3>In store</h3>

            <div className="book-list-wrapper">
              <ul>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
              </ul>
              <button className="View-all">View All</button>

              <div className="store-items-block-wrapper">
                <h3>Best Seller</h3>
                <div className="store-items-block">
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                  <div className="image-cell">
                    <div className="image-part">
                      <img src={bookCover} alt="book-img" />
                    </div>
                    <p className="image-name">bbbbbb</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      //Merge Engine End DefaultContainer
    );
  }
}
//@ts-ignore
const viewAllBooksWithRouter = withRouter(viewAllBooks);
export default withStyles(styles)(viewAllBooksWithRouter);

// Customizable Area Start
// Customizable Area End
