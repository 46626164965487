import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";

import {
  withStyles,
  createStyles,
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Hidden,
} from "@material-ui/core";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Customizable Area End

import AdminDashboardController, {
  Props,
  configJSON,
} from "./AdminDashboardController";

import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";

import {
  blueEyes,
  threeDot,
  EditIcon,
  BlockIcon,
  DeleteIcon,
  pinkDownload,
  dollor,
  subscriptionIcon,
} from "./assets";

class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = (): any => {
    if (this.props.authToken) {
      this.getPublishersList();
      this.getStudentsList();
      this.getBooksList();
      this.getStatistics();
    }
  };
  componentDidUpdate(prevProps: any) {
    if (prevProps.authToken !== this.props.authToken) {
      this.getPublishersList();
      this.getStudentsList();
      this.getBooksList();
      this.getStatistics();
    }
  }
  handleClick = (event: any, value: any) => {
    this.setState({ selectedIndex: value, anchorEl: event.currentTarget });
  };
  handleRoutes = (route: any, state: any) => {
    this.props.history.push({
      pathname: route,
      state: state,
    });
  };
  handleClose = (value: any) => {
    if (value === "edit") {
      // this.setState({ selectedIndex: null, anchorEl: null });
      this.handleRoutes("/AdminConsole/EditPublisher", {
        publisher: this.state.publishersList[this.state.selectedIndex],
      });
    } else if (value === "block") {
      this.blockPublisher();
    } else if (value === "delete") {
      this.deletePublisher();
    } else {
      this.setState({ selectedIndex: null, anchorEl: null });
    }
  };

  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    const { statistics } = this.state;
    return (
      <Box m={3} className={classes.root}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={2}>
              <div
                style={{
                  height: "90vh",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "0.3rem",
                }}
              >
                <Box m={1} textAlign="center">
                  <Typography variant="h6">
                    John Mike <br /> Admin
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button selected={true}>
                      <ListItemText
                        primary="Upload Media"
                        onClick={() => this.handleRoutes("/AdminConsole", null)}
                      />
                    </ListItem>
                    <ListItem button>
                      {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                      <ListItemText
                        primary="Students"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/students", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Publishers"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/publishers", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="All Books"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/AllBooks", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Notes"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Notes", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Subscriptions"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Subscriptions", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Logout"
                        onClick={this.props.logout}
                      />
                    </ListItem>
                  </List>
                </Box>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={10}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography color="textPrimary">home</Typography>
            </Breadcrumbs>
            <Box mt={3}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">Upload Media</Typography>
                  <div className={classes.uploadContainer}>
                    <Box m={1} p={1}>
                      <Typography variant="h5">Drag and Drop Files</Typography>
                    </Box>
                    <Box m={1} mb={2} p={1}>
                      <Typography variant="body2">
                        Upload book PDF files here to get started
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/UploadMedia", null)
                        }
                      >
                        Or choose file
                      </Button>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">Statistics</Typography>
                  <Paper className={classes.StatisticsContainer}>
                    <Grid container>
                      <Grid
                        style={{ borderRadius: "1rem 0 0 0" }}
                        item
                        xs={6}
                        className={classes.StatisticsItems}
                      >
                        <Box display="flex">
                          <Box pr={1} component="span">
                            <img
                              src={subscriptionIcon}
                              width="15px"
                              height="15px"
                            />
                          </Box>
                          <Typography variant="body2">Subcriptions</Typography>
                        </Box>
                        <Typography variant="h4">
                          {statistics.subscriptions
                            ? statistics.subscriptions
                            : ""}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1} pr={5}>
                            <LinearProgress
                              variant="determinate"
                              color="secondary"
                              value={50.0}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ borderRadius: "0 1rem 0 0" }}
                        className={classes.StatisticsItems}
                      >
                        <Box display="flex">
                          <Box pr={1} component="span">
                            <img src={blueEyes} width="15px" height="15px" />
                          </Box>
                          <Typography variant="body2">Students</Typography>
                        </Box>

                        <Typography variant="h4">
                          {statistics.students ? statistics.students : ""}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1} pr={5}>
                            <LinearProgress
                              variant="determinate"
                              color="primary"
                              value={50.0}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          borderRadius: "0 0 0 1rem",
                          padding: "1.6rem 0.5rem 0.5rem 1.6rem",
                        }}
                        className={classes.StatisticsItems}
                      >
                        <Typography variant="body2">Publishers</Typography>
                        <Typography variant="h4">
                          {statistics.publishers ? statistics.publishers : ""}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1} pr={5}>
                            <LinearProgress
                              variant="determinate"
                              value={50.0}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ borderRadius: "0 0 1rem 0" }}
                        className={classes.StatisticsItems}
                      >
                        <Box display="flex">
                          <Box pr={1} component="span">
                            <img
                              src={pinkDownload}
                              width="15px"
                              height="15px"
                            />
                          </Box>
                          <Typography variant="body2">
                            Total Downloads
                          </Typography>
                        </Box>
                        <Typography variant="h4">
                          {statistics.downloads ? statistics.downloads : ""}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1} pr={5}>
                            <LinearProgress
                              variant="determinate"
                              color="secondary"
                              value={50.0}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={5} className={classes.bookContainer}>
                    <TableContainer
                      component={Paper}
                      className={classes.bookContainer}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                          <TableRow>
                            <TableCell className={classes.th}>
                              BOOK NAME
                            </TableCell>
                            <TableCell className={classes.th} align="right">
                              NOTES ADDED
                            </TableCell>
                            <TableCell className={classes.th} align="right">
                              TOTAL USERS
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.booksList.map((row: any) => (
                            <TableRow key={row.name}>
                              <TableCell
                                className={classes.th}
                                component="th"
                                scope="row"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell className={classes.th} align="right">
                                {row.noted_added}
                              </TableCell>
                              <TableCell className={classes.th} align="right">
                                {row.total_user}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.earningContainer}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              borderRadius: "1rem 0 0 0",
                              borderRight: "0px",
                            }}
                            className={classes.earningItems}
                          >
                            <Typography variant="body2">
                              Notes Published
                            </Typography>
                            <Typography variant="h4">
                              {statistics.notes_published
                                ? statistics.notes_published
                                : ""}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Box width="100%" mr={1}>
                                <LinearProgress
                                  variant="determinate"
                                  color="secondary"
                                  value={50.0}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              borderRadius: "0 0 0 1rem",
                              borderRight: "0px",
                            }}
                            className={classes.earningItems}
                          >
                            <Typography variant="body2">
                              Notes Purchaged
                            </Typography>
                            <Typography variant="h4">
                              {statistics.notes_purchased
                                ? statistics.notes_purchased
                                : ""}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Box width="100%" mr={1}>
                                <LinearProgress
                                  variant="determinate"
                                  color="primary"
                                  value={50.0}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={7} className={classes.earningTitle}>
                        <Box
                          m={3}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box component="span">
                            <img src={dollor} />
                          </Box>
                          <Typography variant="h6" style={{ lineHeight: 1 }}>
                            Total
                            <br /> Earning
                          </Typography>
                        </Box>
                        <Box ml={3}>
                          <Typography variant="h4">
                            ${" "}
                            {statistics.total_earnings
                              ? statistics.total_earnings
                              : "0"}
                          </Typography>
                        </Box>
                        <Box ml={3} mr={5} display="flex" alignItems="center">
                          <Box width="100%" mr={1}>
                            <LinearProgress
                              variant="determinate"
                              value={50.0}
                            />
                          </Box>
                        </Box>
                        <Box m={3}>
                          <Typography variant="body2">
                            {statistics.percent_difference
                              ? statistics.percent_difference >= 0
                                ? statistics.percent_difference +
                                  " more earnings than last month"
                                : statistics.percent_difference.slice(1) +
                                  " less earnings than last month"
                              : "0.0% "}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Typography variant="h5">Publisher List</Typography>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                    <TableRow>
                      <TableCell className={classes.th}>USER</TableCell>
                      <TableCell className={classes.th} align="right">
                        BOOKS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        DOWNLOADS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        VIEWERS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        REVIEWS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        EARNINIG
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        STATUS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.publishersList.map((row: any, i: any) => (
                      <TableRow key={row.email}>
                        <TableCell className={classes.th}>
                          {row.name}
                          <br />
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.totalbooks + " Books"}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.totaldownloads}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.totalviews}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.totalviews}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.totalearnings}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.status === "active" ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              className={classes.active}
                            >
                              Active
                            </Button>
                          ) : row.status === "suspended" ? (
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className={classes.blocked}
                            >
                              Suspended
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className={classes.blocked}
                            >
                              Blocked
                            </Button>
                          )}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          <Button
                            aria-controls="selectActionButton"
                            aria-haspopup="true"
                            onClick={(e) => this.handleClick(e, i)}
                          >
                            <img src={threeDot} height="25px" alt="three dot" />
                          </Button>
                          <Menu
                            id="selectActionButton"
                            keepMounted
                            open={i === this.state.selectedIndex}
                            onClose={this.handleClose}
                            anchorEl={this.state.anchorEl}
                          >
                            <MenuItem onClick={() => this.handleClose("edit")}>
                              <Box mr={1}>
                                <img src={EditIcon} width="18px" alt="icon" />
                              </Box>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => this.handleClose("block")}>
                              <Box mr={1}>
                                <img src={BlockIcon} width="20px" alt="icon" />
                              </Box>
                              Block
                            </MenuItem>
                            <MenuItem
                              onClick={() => this.handleClose("delete")}
                            >
                              <Box mr={1}>
                                <img src={DeleteIcon} width="15px" alt="icon" />
                              </Box>
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box mt={3}>
              <Typography variant="h5">Student List</Typography>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                    <TableRow>
                      <TableCell className={classes.th}>USER</TableCell>
                      <TableCell className={classes.th} align="right">
                        SUBSCRIPTIONS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        BOOK DOWNLOADS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        NOTES PURCHASED
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        NOTES WRITTEN
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        EARNINGS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        NOTES REVIEWS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.studentList.map((row: any) => (
                      <TableRow key={row.email}>
                        <TableCell className={classes.th} align="left">
                          {row.name} <br /> {row.email}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.last_subscription ? row.last_subscription : "-"}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.viewer_count}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.notes_purchased}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.notes_written}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.earnings}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.note_reviews}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Customizable Area Start

const AdminDashboardAlertBox = withAlertBox(AdminDashboard);
const AdminDashboardLoader = withLoader(AdminDashboardAlertBox);
const AdminDashboardToast = withToast(AdminDashboardLoader);
// Customizable Area End
export default withStyles((theme) =>
  createStyles({
    root: {},
    uploadContainer: {
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
      textAlign: "center",
      padding: "2rem",
      marginRight: "2rem",
      marginTop: "1rem",
    },
    StatisticsContainer: {
      borderRadius: "1rem",
      marginTop: "1rem",
    },
    StatisticsItems: {
      border: "1px solid #f1f1f1",
      padding: "1.6rem",
    },
    earningContainer: {
      borderRadius: "1rem",
      marginTop: "1rem",
    },
    earningItems: {
      border: "1px solid #f1f1f1",
      padding: "1.6rem",
    },
    earningTitle: {
      margin: "1rem",
      padding: "1rem",
      backgroundColor: "#f1f1f1",
      borderRadius: "0.5rem",
    },
    tableContainer: {
      height: "260px",
    },
    bookContainer: {
      height: "260px",
      [theme.breakpoints.up("sm")]: {
        marginRight: "2rem",
      },
    },
    th: {
      fontSize: "0.775rem",
    },
    blocked: {
      borderRadius: "1rem",
      backgroundColor: "#f5440017",
      fontSize: "12px",
    },
    active: {
      borderRadius: "1rem",
      backgroundColor: "#c0caff8c",
      fontSize: "12px",
      color: "#0127ff",
    },
  })
)(AdminDashboardToast);
