import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router";
import {
  withStyles,
  createStyles,
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Drawer,
} from "@material-ui/core";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

type myProps = RouteComponentProps & {
  isDrawerOpen: boolean;
  toggleDrawer: any;
  history: any;
  logout: any;
};

function SideBar({ isDrawerOpen, toggleDrawer, history, logout }: myProps) {
  const classes = useStyles();
  const handleRoutes = (route: any, state: any) => {
    history.push({
      pathname: route,
      state: state,
    });
  };
  return (
    <React.Fragment>
      <Drawer open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <div>
            <Box m={1} textAlign="center">
              <Typography variant="h6">
                John Mike <br /> Admin
              </Typography>
              <Divider />
              <List component="nav" aria-label="main mailbox folders">
                <ListItem
                  button
                  selected={location.pathname === "/AdminConsole"}
                >
                  <ListItemText
                    primary="Upload Media"
                    onClick={() => handleRoutes("/AdminConsole", null)}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={location.pathname === "/AdminConsole/students"}
                >
                  {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                  <ListItemText
                    primary="Students"
                    onClick={() => handleRoutes("/AdminConsole/students", null)}
                  />
                </ListItem>
                <ListItem
                  button
                  selected={location.pathname === "/AdminConsole/publishers"}
                >
                  <ListItemText
                    primary="Publishers"
                    onClick={() =>
                      handleRoutes("/AdminConsole/publishers", null)
                    }
                  />
                </ListItem>

                <ListItem button>
                  <ListItemText
                    primary="All Books"
                    onClick={() => handleRoutes("/AdminConsole/AllBooks", null)}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemText
                    primary="Notes"
                    onClick={() => handleRoutes("/AdminConsole/Notes", null)}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemText
                    primary="Subscriptions"
                    onClick={() =>
                      handleRoutes("/AdminConsole/Subscriptions", null)
                    }
                  />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="logout" onClick={logout} />
                </ListItem>
              </List>
            </Box>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default withRouter(SideBar);
