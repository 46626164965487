Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.CatelogueLabel = "Book Details";

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.GetAllBooksAPiEndPoint = "bx_block_book/books";
exports.GetMyBooksAPiEndPoint = "bx_block_book/my_books";
exports.getAllNotesAPiEndPoint = "bx_block_notes/my_notes";
exports.postSearchBookAPiEndPoint = "bx_block_book/search_book";
// Customizable Area End
