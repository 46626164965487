import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  CheckBox,
} from "react-native";
import Radio from "@material-ui/core/Radio";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import SocialMedia from "../../social-media-account-registration/src/SocialMediaAccountRegistrationScreen.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { Link } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SocialMediaLogin from "../../social-media-account-login/src/SocialMediaAccountLoginScreen.web";
//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";
// import { Link } from 'react-router-dom';
// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationWebController";
import { fbIcon, googleIcon, background } from "./assets";
import SocialMediaAccountRegistrationScreen from "../../social-media-account-registration/src/SocialMediaAccountRegistrationScreen.web";
// import { background } from './assets';

const styles = {
  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${background})`,
    // backgroundColor:"blue"
  },
  firstCard: {
    height: "auto",
    width: "350px",
    backgroundColor: "white",
    borderRadius: "25px",
    border: "1px solid #f5f6fa",
    // boxShadow: "0 30px 5px -5px #215478",
    padding: "30px",
  },
  button: {
    backgroundColor: "#4caeef",
    color: "white",
  },
  box: {
    height: "40px",
    width: "40px",
    backgroundColor: "#4caeef",
    borderRadius: "8px",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  singIn: {
    display: "flex",
    justifyContent: "space-between",
    // marginRight:"30px"
    // flexDirection:"row"
  },
  confirmInput: {
    marginTop: "20px",
    marginBottom: "20px",
  },
} as any;
class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  changeRadioButton = (e?: any) => {
    this.setState({
      selectedValue: e.target.value,
    });
  };
  checkPolicy = () => {
    this.setState({
      checkedB: true,
    });
  };
  render() {
    const { navigation, classes } = this.props;

    return (
      <div className={classes.mainDiv}>
        <div className={classes.cards}>
          <div className={classes.firstCard}>
            <div className={classes.singIn}>
              <p style={{ marginRight: "40px" }}>Sign Up</p>
              {/* <div style={{ display: "flex" }}>
                <div className={classes.box}>
                  <img src={fbIcon} style={{ height: "20px", width: "20px" }} />
                </div>
                <div className={classes.box}>
                  <img
                    src={googleIcon}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
              </div> */}
              <SocialMediaAccountRegistrationScreen />
            </div>

            <div style={{ marginTop: "5%" }}>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  // animal: "",
                  password: "",

                  // confirmPassword:'',
                  // confirmPassword: "",
                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Full name is required"),
                  email: Yup.string().required("Email is required"),
                  // .email("Invalid email address")
                  // .required("Please enter an email address"),
                  password: Yup.string()
                    .matches(
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
                      "Your password must contains atleast 8 or more characters with a mix of letters, numbers & special characters"
                    )
                    .required("Password is required "),
                })}
                onSubmit={(values) => {
                  console.log(values);
                  if (this.state.selectedValue === "") {
                    this.setState({
                      radioButtonValidation: true,
                    });
                  } else {
                    this.createAccount(values);
                  }
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="name"
                      type="text"
                      size="small"
                      style={{ display: "block" }}
                      fullWidth
                      // style={{width:"400px"}}
                      className={classes.confirmInput}
                      label="Full name"
                      value={values.name}
                      variant="outlined"
                      helperText={
                        <span style={{ color: "red" }}>{errors.name}</span>
                      }
                      error={errors.name && touched.name ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p>Make your account as</p>
                    <FormControlLabel
                      value="end"
                      control={
                        <Radio
                          color="primary"
                          value="student"
                          onChange={this.changeRadioButton}
                          checked={this.state.selectedValue == "student"}
                        />
                      }
                      label="Student"
                    />
                    <FormControlLabel
                      value="end"
                      control={
                        <Radio
                          color="primary"
                          value="publisher"
                          onChange={this.changeRadioButton}
                          checked={this.state.selectedValue == "publisher"}
                        />
                      }
                      label="Publisher"
                    />
                    {this.state.radioButtonValidation == true ? (
                      <p>Make you account is invalid</p>
                    ) : (
                      <></>
                    )}
                    <TextField
                      name="email"
                      type="email"
                      size="small"
                      fullWidth
                      // style={{width:"400px"}}
                      style={{ display: "block" }}
                      className={classes.confirmInput}
                      label="Email"
                      value={values.email}
                      variant="outlined"
                      helperText={
                        <span style={{ color: "red" }}>{errors.email}</span>
                      }
                      error={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="password"
                      type="password"
                      size="small"
                      style={{ display: "block" }}
                      fullWidth
                      // style={{width:"400px"}}
                      // style={{width:"300px"}}
                      // style={{width:"400px"}}
                      label="Password"
                      value={values.password}
                      variant="outlined"
                      className={classes.confirmInput}
                      helperText={
                        <span style={{ color: "red" }}>{errors.password}</span>
                      }
                      error={errors.password && touched.password ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div>
                      <div className={classes.forgotPassword}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.checkedB}
                              onChange={this.checkPolicy}
                              value={this.state.checkedB}
                              // name="checkedB"
                              color="primary"
                            />
                          }
                          label="I agree privacy policy in using this App"
                        />
                      </div>
                      <div className={classes.forgotPassword}>
                        <Button
                          className={classes.button}
                          type="submit"
                          fullWidth
                          variant="contained"
                        >
                          Continue
                        </Button>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          Already have an account?{" "}
                          <Link
                            style={{ color: "#4caeef" }}
                            onClick={this.goToPage}
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EmailAccountRegistration);
