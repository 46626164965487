export const bookCover = require("../assets/bookCover.png");
export const viewIcon = require("../assets/Views.png");
export const userIcon = require("../assets/user.png");
export const bookLogo = require("../assets/peddler.png");
export const playButton = require("../assets/Play Button.png");
export const Published = require("../assets/Published Note_Icon.png");
export const selectArrow = require("../assets/Select_Arrow.png");
export const downloads = require("../assets/Downloads_Inactive.png");
export const downloadsActive = require("../assets/Downloads_Active.svg");

export const Notes = require("../assets/Published Note_Icon.png");
export const NotesActive = require("../assets/Notes_Active.svg");
export const earnings = require("../assets/Earnings_Inactive.svg");
export const earningsActive = require("../assets/Earnings_Active.png");

export const subscription = require("../assets/Subscription_Inactive.svg");
export const subscriptionActive = require("../assets/Subscription_Active.svg");

export const paymentHistory = require("../assets/Payment History_Icon_Inactive.svg");
export const purchasedNotes = require("../assets/Purchased Notes_Inactive.svg");
export const purchasedNotesActive = require("../assets/Purchased Notes_Active.png");
export const paymentActive = require("../assets/Payment History_Icon_Active.png");
export const paymentInactive = require("../assets/Payment History_Icon_Inactive.svg");
export const viewerIcon = require("../assets/Viewers Icon.png");
export const downloadIcon = require("../assets/ICON Downloads.png");

export const dollerIcon = require("../assets/Earnings_Icon.png");

export const sampleReadIcon = require("../assets/Group_Subscription.svg");
export const searchImage = require("../assets/Search.png");
