import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";

import {
  withStyles,
  createStyles,
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  MenuItem,
  Select,
  Hidden,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Customizable Area End

import UploadMediaController, {
  Props,
  S,
  configJSON,
} from "./UploadMediaController";
import FormLabel from "@material-ui/core/FormLabel";

import { pinkDownload, UploadImage, pdfIcon, musicIcon } from "./assets";

const Schema = Yup.object().shape({
  autherName: Yup.string().required("This field is required."),
  publisherName: Yup.string().required("This field is required."),
  language: Yup.string().required("This field is required."),
  writtenBy: Yup.string().required("This field is required."),
  bookDesc: Yup.string().required("This field is required."),
  price: Yup.number().required("This field is required."),
  discount: Yup.number().required("This field is required."),
  thumbnail: Yup.string().required("This field is required."),
  bookPdf: Yup.string().required("This field is required."),
});

class UploadMedia extends UploadMediaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = (): any => {
    if (this.props.authToken) {
      this.getPublishersList();
    }
  };
  componentDidUpdate = (prevProps: any) => {
    if (prevProps.authToken !== this.props.authToken) {
      this.getPublishersList();
    }
  };
  handleRoutes = (route: any) => {
    this.props.history.push(route);
  };

  toggleModel = (): any => {
    this.setState((prevState: S) => ({ open: !prevState.open }));
  };
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    return (
      <Box m={3} className={classes.root}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={2}>
              <div
                style={{
                  height: "100vh",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "0.3rem",
                }}
              >
                <Box m={1} textAlign="center">
                  <Typography variant="h6">
                    John Mike <br /> Admin
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button selected={true}>
                      <ListItemText
                        primary="Upload Media"
                        onClick={() => this.handleRoutes("/AdminConsole")}
                      />
                    </ListItem>
                    <ListItem button>
                      {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                      <ListItemText
                        primary="Students"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Students")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Publishers"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Publishers")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="All Books"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/AllBooks")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Notes"
                        onClick={() => this.handleRoutes("/AdminConsole/Notes")}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Subscriptions"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Subscriptions")
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Logout"
                        onClick={this.props.logout}
                      />
                    </ListItem>
                  </List>
                </Box>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={10}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/AdminConsole">
                Home
              </Link>
              <Typography color="textPrimary">Upload Media</Typography>
            </Breadcrumbs>
            <Box mt={3}>
              <Formik
                initialValues={{
                  autherName: "",
                  publisherName: "",
                  language: "",
                  writtenBy: "",
                  bookDesc: "",
                  price: "",
                  discount: "",
                  thumbnail: "",
                  bookPdf: "",
                }}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={(values, actions) => {
                  this.handleBookSave(values);
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    setFieldValue,
                    handleChange,
                    touched,
                    errors,
                  } = formikProps;
                  return (
                    <Form translate="no" noValidate autoComplete="off">
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5">Upload Media</Typography>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.uploadContainer}
                          >
                            <Box m={1} p={1}>
                              <Typography variant="h6">
                                Upload Book Thumbnail
                              </Typography>
                              <Typography variant="body2">
                                This will be your Cover Picture.
                              </Typography>
                            </Box>
                            <Box mb={3}>
                              <input
                                type="file"
                                hidden
                                id="thumbnail"
                                name="thumbnail"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "thumbnail",
                                    e.currentTarget.files
                                  );
                                }}
                              />
                              <label htmlFor="thumbnail">
                                <Button
                                  variant="contained"
                                  component="span"
                                  color="primary"
                                >
                                  <Box pr={1} component="span">
                                    <img
                                      src={UploadImage}
                                      alt="upload"
                                      width="20px"
                                    />
                                  </Box>
                                  Upload File
                                </Button>
                              </label>
                            </Box>
                          </Box>
                          <div className={classes.uploadContainer}>
                            <Box m={1} p={1} display="flex">
                              <Box mr={2}>
                                <Typography variant="h6">
                                  Drag and drop files
                                </Typography>
                                <Typography variant="body2">
                                  Upload book PDF and Audio MP3 <br /> here to
                                  get started
                                </Typography>
                              </Box>
                              <Box display="flex" ml="auto">
                                <Box m={2}>
                                  <img
                                    src={musicIcon}
                                    width="30px"
                                    alt="music"
                                  />
                                </Box>
                                <Box m={2}>
                                  <img src={pdfIcon} width="30px" alt="pdf" />
                                </Box>
                              </Box>
                            </Box>
                            <Box mb={3}>
                              <input
                                type="file"
                                hidden
                                id="bookPdf"
                                name="bookPdf"
                                multiple
                                accept="application/pdf, audio/wav"
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "bookPdf",
                                    e.currentTarget.files
                                  );
                                }}
                              />
                              <label htmlFor="bookPdf">
                                <Button
                                  fullWidth
                                  variant="contained"
                                  component="span"
                                  color="primary"
                                >
                                  <Box pr={1} component="span">
                                    <img
                                      src={UploadImage}
                                      alt="upload"
                                      width="20px"
                                    />
                                  </Box>
                                  Upload File
                                </Button>
                              </label>
                            </Box>
                          </div>
                          <Box m={2} mr={5}>
                            <Divider />
                          </Box>
                          <Grid
                            container
                            spacing={3}
                            className={classes.bookUploadContainer}
                          >
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="body1">
                                    Auther Name
                                  </Typography>
                                  <TextField
                                    required
                                    name="autherName"
                                    id="autherName"
                                    value={values.autherName}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="filled"
                                    placeholder="Eg: Mike Smith"
                                    error={
                                      touched.autherName &&
                                      Boolean(errors.autherName)
                                    }
                                    helperText={
                                      touched.autherName && errors.autherName
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body1">
                                    Publisher Name
                                  </Typography>
                                  <Select
                                    required
                                    fullWidth
                                    variant="filled"
                                    name="publisherName"
                                    placeholder="Eg: Mike Smith"
                                    id="publisherName"
                                    value={values.publisherName}
                                    onClick={(e: any) => {
                                      if (e.target.value === "addPublisher") {
                                        this.toggleModel();
                                      } else handleChange(e);
                                    }}
                                    error={
                                      touched.publisherName &&
                                      Boolean(errors.publisherName)
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Select Publisher
                                    </MenuItem>
                                    {this.state.publishersList.map(
                                      (publisher: any) => {
                                        return (
                                          <MenuItem
                                            key={publisher.id}
                                            value={publisher.id}
                                          >
                                            {publisher.name
                                              ? publisher.name
                                              : publisher.email}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    <MenuItem
                                      key="addPublisher"
                                      value="addPublisher"
                                    >
                                      Add Publisher
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box display="flex">
                                    <Box mr={1}>
                                      <Typography variant="body1">
                                        Add Pricing
                                      </Typography>
                                      <TextField
                                        required
                                        variant="filled"
                                        name="price"
                                        id="price"
                                        onChange={handleChange}
                                        value={values.price}
                                        placeholder="Eg: 25"
                                        error={
                                          touched.price && Boolean(errors.price)
                                        }
                                        helperText={
                                          touched.price && errors.price
                                        }
                                      />
                                    </Box>
                                    <Box ml={2}>
                                      <Typography variant="body1">
                                        Add Discount
                                      </Typography>
                                      <Field
                                        component={TextField}
                                        required
                                        variant="filled"
                                        name="discount"
                                        id="discount"
                                        onChange={handleChange}
                                        value={values.discount}
                                        placeholder="Eg: 5"
                                        error={
                                          touched.discount &&
                                          Boolean(errors.discount)
                                        }
                                        helperText={
                                          touched.discount && errors.discount
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box display="flex">
                                    <Box mr={1}>
                                      <Typography variant="body2">
                                        Language
                                      </Typography>
                                      <Field
                                        component={TextField}
                                        required
                                        variant="filled"
                                        name="language"
                                        id="language"
                                        onChange={handleChange}
                                        value={values.language}
                                        placeholder="Eg: English"
                                        error={
                                          touched.language &&
                                          Boolean(errors.language)
                                        }
                                        helperText={
                                          touched.language && errors.language
                                        }
                                      />
                                    </Box>
                                    <Box ml={2}>
                                      <Typography variant="body1">
                                        Written By
                                      </Typography>
                                      <Field
                                        component={TextField}
                                        required
                                        variant="filled"
                                        name="writtenBy"
                                        id="writtenBy"
                                        onChange={handleChange}
                                        value={values.writtenBy}
                                        placeholder="Eg: 5"
                                        error={
                                          touched.writtenBy &&
                                          Boolean(errors.writtenBy)
                                        }
                                        helperText={
                                          touched.writtenBy && errors.writtenBy
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body1">
                                    Book Description
                                  </Typography>
                                  <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rowsMax={10}
                                    variant="filled"
                                    name="bookDesc"
                                    id="bookDesc"
                                    onChange={handleChange}
                                    value={values.bookDesc}
                                    placeholder="Eg: As far back as 1840 Turner..."
                                    error={
                                      touched.bookDesc &&
                                      Boolean(errors.bookDesc)
                                    }
                                    helperText={
                                      touched.bookDesc && errors.bookDesc
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5">Preview</Typography>
                          <Paper className={classes.previewContainer}>
                            <Grid container spacing={4}>
                              <Grid item xs={7}>
                                <Paper>
                                  <Box p={2} display="flex">
                                    <Box m={1}>
                                      <img
                                        src={pinkDownload}
                                        alt="Book cover page"
                                      />
                                    </Box>
                                    <Box m={1}>
                                      <Typography variant="subtitle1">
                                        Book Name
                                      </Typography>
                                      <Typography variant="subtitle2">
                                        Auther Name
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Paper>
                              </Grid>
                              <Grid item xs={5}>
                                <Box m={1}>
                                  <Typography variant="h6">
                                    About Book:
                                  </Typography>
                                  <Box display="flex">
                                    <Box>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="subtitle1"
                                      >
                                        Writtern By:
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="subtitle1"
                                      >
                                        Length:
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="subtitle1"
                                      >
                                        Language:
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="subtitle1"
                                      >
                                        Publisher:
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="body1"
                                      >
                                        {values.writtenBy
                                          ? values.writtenBy
                                          : " Eg: Mike Smith"}
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="body1"
                                      >
                                        Eg: 6hr
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="body1"
                                      >
                                        Eg: English
                                      </Typography>
                                      <Typography
                                        className={classes.AboutBookBody}
                                        variant="body1"
                                      >
                                        {/* Eg: Mike Smith */}
                                        {values.publisherName
                                          ? this.state.publishersList.filter(
                                              (publihser: any) =>
                                                publihser.id ===
                                                values.publisherName
                                            )[0].name
                                          : "Eg: Mike Smith "}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box m={3} mt={0}>
                                  <Paper>
                                    <Box p={3} pt={0} pb={5}>
                                      <Typography variant="h6">
                                        Summery
                                      </Typography>
                                      <Typography variant="body2">
                                        Eg: As far back as 1840 Turner...
                                      </Typography>
                                    </Box>
                                  </Paper>
                                </Box>
                                <Box m={3}>
                                  <Paper>
                                    <Button
                                      type="button"
                                      fullWidth
                                      variant="contained"
                                      color="default"
                                    >
                                      Read or Listen to a Sample
                                    </Button>
                                  </Paper>
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                          <Box m={3}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                            >
                              Upload Book
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.toggleModel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Publisher</DialogTitle>
          <form onSubmit={this.addPublisher}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="publisherName"
                label="publisherName"
                type="text"
                fullWidth
                required
                onChange={(e) =>
                  this.setState({ publisherName: e.target.value })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={this.toggleModel} color="primary">
                Cancel
              </Button>
              <Button type="submit" onClick={this.toggleModel} color="primary">
                Subscribe
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    );
  }
}

// Customizable Area Start

const UploadMediaAlertBox = withAlertBox(UploadMedia);
const UploadMediaLoader = withLoader(UploadMediaAlertBox);
const UploadMediaToast = withToast(UploadMediaLoader);
// Customizable Area End
export default withStyles((theme) =>
  createStyles({
    root: {},
    uploadContainer: {
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
      padding: "1rem",
      marginRight: "2rem",
      marginTop: "1rem",
      border: "2px dashed gray",
    },
    bookUploadContainer: {
      marginRight: "3rem",
      padding: "2rem 2rem 2rem 1rem",
    },
    previewContainer: {
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
      padding: "1rem",
      marginTop: "1rem",
    },
    AboutBookBody: {
      fontSize: "0.8rem",
      lineHeight: "1.5rem",
    },
  })
)(UploadMediaToast);
