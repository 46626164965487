import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { withRouter, Link } from "react-router-dom";
import HeaderController, { Props, S } from "./HeaderController.web";
import Loader from "../../../components/src/Loader.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";

const styles = {
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },

  search: {
    position: "relative",
    background: "#5196DD",
    // border:"1px solid red",
    // backgroundCo #4CAEEF
    color: "white",
    //   backgroundColor:"rgb(68, 156, 214)",
    //   marginLeft:"0",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    width: "100%",
    padding: "13px 50px",
  },
  // notesForSale: {
  //   backgroundColor: "#F2F3F2",
  //   height: "auto",
  //   margin: "20px",
  //   borderRadius: "8px",
  // },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    minWidth: 120,
  },
} as any;

class AppHeader extends HeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <AppBar style={{ backgroundColor: "#4caeef" }}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <div style={{ padding: "10px" }}>
                <img
                  src={require("./images/Logo_White.png")}
                  style={{
                    height: "50px",
                    width: "70px",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    //@ts-ignore
                    this.props.history.push("/Catalogue/Home");
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ margin: "23px" }}
                >
                  Age
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Browse"
                >
                  {/* style={{background:"#5196DD",color:"white"}} */}
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.search}>
                <div
                  className={classes.searchIcon}
                  // onClick={() => {
                  //   console.log("1qswedfrgthyjuk");
                  //   this.state.searchInput != "" &&
                  //     //@ts-ignore
                  //     this.props.history.push("/search-books");
                  // }}
                >
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={this.state.searchInput && this.state.searchInput}
                  onChange={(e: any) => {
                    this.setState({
                      searchInput: e.target.value,
                    });
                  }}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter" && this.state.searchInput != "") {
                      StorageProvider.set("search", this.state.searchInput);
                      //@ts-ignore
                      this.props.history.push("/search-books");
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <NotificationsNoneIcon />
              </div>
            </Grid>
          </Grid>
        </AppBar>
      </>
    );
  }
}

//@ts-ignore
const AppHeaderRouter = withRouter(AppHeader);
export default withStyles(styles)(AppHeaderRouter);
