import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeController, { Props, S } from "./HomeController.web";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import SideBar from "./Sidebar.web";
import AppHeader from "../../../components/src/Header/index";
import BookListing from "../../../components/src/BookListing/index";
import { withRouter, Link } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import "../assets/css/home.css";
import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
} from "./assets";
//import styles from '../assets/css/index'

const styles = {
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },

  search: {
    position: "relative",
    background: "#5196DD",
    // border:"1px solid red",
    // backgroundCo #4CAEEF
    color: "white",
    //   backgroundColor:"rgb(68, 156, 214)",
    //   marginLeft:"0",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    width: "100%",
    padding: "13px 50px",
  },
  // notesForSale: {
  //   backgroundColor: "#F2F3F2",
  //   height: "auto",
  //   margin: "20px",
  //   borderRadius: "8px",
  // },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    // margin: "23px",

    minWidth: 120,
  },
} as any;
class Home extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <AppHeader />
        <Loader loading={this.state.Loader} />
        <div className="catalog-home">
          <SideBar selectedIndex={0} className="col-2" />
          <div className="content">
            <p className="color-gray">Home</p>
            <p className="color-gray mb-0">Welcome Tran</p>
            <h3>In store</h3>
            {/* <div className="store-items-block">
              {this.state.allBooks &&
                this.state.allBooks.map((x: any, index: any) => {
                  return (
                    <div className="image-cell" key={x.id}>
                      <div className="price">
                        <img
                          src={playButton}
                          onClick={() => {
                            //@ts-ignore
                            this.props.history.replace("/BookRead");
                          }}
                        />
                        <strong>${x.attributes.price}</strong>
                      </div>
                      <div
                        className="image-block"
                        onClick={() => {
                          const { history }: any = this.props;
                          history.push(
                            `/Catalogue/BookDetails/${x.attributes.id}`
                          );
                        }}
                      >
                        <img src={x.attributes.cover_image_url} />
                      </div>

                      <p className="image-name">{x.attributes.name}</p>
                    </div>
                  );
                })}
              {this.state.allBooks && (
                <div className="view-books">
                  <span>
                    View all <br /> books
                  </span>
                  <button className="btn-next" />
                </div>
              )}
            </div> */}
            <BookListing
              allBooks={this.state.allBooks && this.state.allBooks}
            />
            {this.state.allBooks && (
              <div className="notesForSale">
                <div className="listing-block">
                  <div className="title">
                    <h3>Notes for sale</h3>
                  </div>
                  <div className="custom-scroll">
                    <ul>
                      {this.state.allBooks &&
                        this.state.allBooks.map((x: any, index: any) => {
                          return (
                            // (this.state.notesViewMore
                            //   ? index < 6
                            //   : index < this.state.allBooks.length)
                            index < 6 && (
                              <li key={x.id}>
                                <div>
                                  <img src={Published} />
                                  <div className="v1">
                                    <p> {x.attributes.name}</p>
                                    <small>{x.attributes.note_count}</small>
                                  </div>
                                </div>
                                <img src={selectArrow} alt="select arrow" />
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>
                  <button
                    className="btn btn-viewall"
                    // onClick={() => {
                    //   this.setState({
                    //     notesViewMore: !this.state.notesViewMore,
                    //   });
                    // }}
                  >
                    View All
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>

      //Merge Engine End DefaultContainer
    );
  }
}
//@ts-ignore
const HomeWithRouter = withRouter(Home);
export default withStyles(styles)(HomeWithRouter);

// Customizable Area Start
// Customizable Area End
