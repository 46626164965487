import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import { withRouter } from "react-router-dom";

import "../assets/css/home.css";
import "../assets/css/searchbook.css";
import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
  bookCover,
  cartIcon,
} from "./assets";
import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = {
  cardRoot: {
    maxWidth: "400px",
    margin: "20px",
  },
} as any;
class SearchBooks extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppHeader />
        <Loader loading={this.state.Loader} />

        <div className="view-book-page catalog-home search-book-ui">
          <SideBar selectedIndex={8} />
          <div className="content">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link href="/Catalogue/Home" style={{ color: "#4caeef" }}>
                Home
              </Link>
              <Typography color="textPrimary">Search Books</Typography>s
            </Breadcrumbs>

            <div className="my-book">
                  <div className="card">
                       <div className="list">
                           <div className="img-part">
                           <img src={bookCover} alt="book-img" />
                           </div>
                           <div className="text">
                              <strong>Book Name</strong>
                              <small className="color-gray">
                               Thirel
                              </small>

                              <div className="details">
                                  <div>
                                      <span>Emily Robbins</span>
                                      <small className="color-blue">3 book available</small>
                                  </div>
                                  <img src={playButton} alt="playbutton" className="playbutton"/>
                              </div>
                           </div>
                          
                       </div>
                       <div className="view-more">
                               <button className="btn"></button>
                           </div>
                  </div>
            </div>
            
            <div className="book-list-wrapper search-book">
              <h3>In Store</h3>
              <ul>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="img-part">
                    <img src={bookCover} alt="book-img" />
                  </div>
                  <div className="details">
                    <div className="left">
                      <span> The world of Abstract Art: Emily Robbins </span>
                      <small>Emily Robbins</small>
                    </div>
                    <div className="right">
                      <strong className="color-gray">45</strong>
                      <strong className="color-black">25$</strong>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button className="View-all" onClick={()=>{
              //@ts-ignore
              this.props.history.push("/view-allBooks");
            }}>View All</button>

          </div>
        </div>
      </>
    );
  }
}
//@ts-ignore
const SearchBooksRouter = withRouter(SearchBooks);
export default withStyles(styles)(SearchBooksRouter);

// Customizable Area Start
// Customizable Area End
