import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";

import {
  withStyles,
  createStyles,
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  List,
  ListItem,
  ListItemText,
  InputBase,
  Hidden,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Customizable Area End

import NotesController, { Props, configJSON } from "./Notes.controller";

import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";

import {
  searchImage,
  threeDot,
  EditIcon,
  BlockIcon,
  DeleteIcon,
} from "./assets";

class Notes extends NotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = (): any => {
    if (this.props.authToken) {
      this.setState({ authToken: this.props.authToken }, () => {
        this.getNotesList();
      });
    }
  };
  componentDidUpdate(prevProps: any) {
    if (prevProps.authToken !== this.props.authToken) {
      this.setState({ authToken: this.props.authToken }, () => {
        this.getNotesList();
      });
    }
  }
  handleRoutes = (route: any, state: any) => {
    this.props.history.push(route);
  };
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    return (
      <Box m={3} className={classes.root}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={2}>
              <div
                style={{
                  height: "90vh",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "0.3rem",
                }}
              >
                <Box m={1} textAlign="center">
                  <Typography variant="h6">
                    John Mike <br /> Admin
                  </Typography>
                  <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      <ListItemText
                        primary="Upload Media"
                        onClick={() => this.handleRoutes("/AdminConsole", null)}
                      />
                    </ListItem>
                    <ListItem button>
                      {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                      <ListItemText
                        primary="Students"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/students", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Publishers"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/publishers", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="All Books"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/AllBooks", null)
                        }
                      />
                    </ListItem>
                    <ListItem button selected>
                      <ListItemText
                        primary="Notes"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Notes", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Subscriptions"
                        onClick={() =>
                          this.handleRoutes("/AdminConsole/Subscriptions", null)
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="Logout"
                        onClick={this.props.logout}
                      />
                    </ListItem>
                  </List>
                </Box>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={10}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link color="inherit" href="/AdminConsole">
                Home
              </Link>
              <Typography color="textPrimary">Notes</Typography>
            </Breadcrumbs>
            <Box mt={3}>
              <Typography variant="h5">Notes</Typography>
              <Box display="flex" mb={3}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img src={searchImage} width="25px" alt="search" />
                  </div>
                  <InputBase
                    placeholder="Search Books, Publisher or Student"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    fullWidth
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Box>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f1f1f1" }}>
                    <TableRow>
                      <TableCell className={classes.th}>NOTE</TableCell>
                      <TableCell className={classes.th}>USER</TableCell>
                      <TableCell className={classes.th} align="right">
                        BOOK
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        NOTES PURCHASED
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        REVIEWS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        EARNINGS
                      </TableCell>
                      <TableCell className={classes.th} align="right">
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.notesList.map((row: any) => (
                      <TableRow key={row.email}>
                        <TableCell className={classes.th} align="left">
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.user_name} <br /> {row.user_email}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.book_name}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.notes_purchased}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.note_reviews}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          {row.earnings}
                        </TableCell>
                        <TableCell className={classes.th} align="right">
                          <Button
                            aria-controls="selectActionButton"
                            aria-haspopup="true"
                            // onClick={(e) => this.handleClick(e, i)}
                          >
                            <img src={threeDot} height="25px" alt="three dot" />
                          </Button>
                          {/* <Menu
                            id="selectActionButton"
                            keepMounted
                            open={i === this.state.selectedIndex}
                            onClose={this.handleClose}
                            anchorEl={this.state.anchorEl}
                          >
                            <MenuItem onClick={() => this.handleClose("edit")}>
                              <Box mr={1}>
                                <img src={EditIcon} width="18px" alt="icon" />
                              </Box>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => this.handleClose("block")}>
                              <Box mr={1}>
                                <img src={BlockIcon} width="20px" alt="icon" />
                              </Box>
                              Block
                            </MenuItem>
                            <MenuItem
                              onClick={() => this.handleClose("delete")}
                            >
                              <Box mr={1}>
                                <img src={DeleteIcon} width="15px" alt="icon" />
                              </Box>
                              Delete
                            </MenuItem>
                          </Menu>
                         */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Customizable Area Start
const NotesAlertBox = withAlertBox(Notes);
const NotesLoader = withLoader(NotesAlertBox);
const NotesToast = withToast(NotesLoader);
// Customizable Area End
export default withStyles((theme) =>
  createStyles({
    root: {},
    th: {
      fontSize: "0.775rem",
    },
    blocked: {
      borderRadius: "1rem",
      backgroundColor: "#f5440017",
      fontSize: "12px",
    },
    active: {
      borderRadius: "1rem",
      backgroundColor: "#c0caff8c",
      fontSize: "12px",
      color: "#0127ff",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#f1f1f1",
      marginLeft: 0,
      width: "100%",
      border: "1px solid black",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  })
)(NotesToast);
