import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  arrayHolder: any;
  allBooks: any;
  myBooks: any;
  notesViewMore: any;
  Loader: any;
  allNotes: any;
  authToken: any;
  bookDetailId: any;
  bookDetail: any;
  searchInput: any;
  searchedBooks: any; // Customizable Area End
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  GetAllBooksApiCallId: string = "";
  myBooksApiCallId: string = "";
  getAllNotesApiCallId: string = "";
  getBookDetailApiCallId: string = "";
  postSearchBookApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      allBooks: "",
      myBooks: "",
      notesViewMore: true,
      Loader: false,
      allNotes: "",
      authToken: "",
      bookDetailId:
        window.location.pathname ==
          `/Catalogue/BookDetails/${window.location.pathname.split("/")[3]}` &&
        window.location.pathname.split("/")[3],
      bookDetail: "",
      searchInput: "",
      searchedBooks: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const authToken = await StorageProvider.get("authToken");
    const search = await StorageProvider.get("search");
    if (authToken) {
      this.setState({ authToken }, () => {
        (window.location.pathname == "/Catalogue/Home" ||
          `/Catalogue/BookDetails/${window.location.pathname.split("/")[3]}`) &&
          this.getAllBooks();
        window.location.pathname == "/Catalogue/MyBooks" && this.myBooks();
        window.location.pathname == "/Catalogue/MyNotes" && this.getAllNotes();
        window.location.pathname ==
          `/Catalogue/BookDetails/${window.location.pathname.split("/")[3]}` &&
          this.getBookDetail();
      });
      window.location.pathname == "/search-books" &&
        (this.postSearchBook(search),
        this.setState({
          searchInput: search,
        }));
    } else {
      const { history }: any = this.props;
      history.push("/");
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    const search = await StorageProvider.get("search");
    if (prevState.authToken !== this.state.authToken) {
      window.location.pathname == "/Catalogue/Home/" && this.getAllBooks();
      window.location.pathname == "/Catalogue/MyBooks/" && this.myBooks();
      window.location.pathname == "/Catalogue/MyNotes/" && this.getAllNotes();
    }

    if (
      prevState.bookDetailId &&
      prevState.bookDetailId != window.location.pathname.split("/")[3]
    ) {
      this.setState({
        bookDetailId: window.location.pathname.split("/")[3],
      });
      setTimeout(() => {
        this.getBookDetail();
      }, 1000);
    }
    if (prevState.searchInput && prevState.searchInput != search) {
      console.log(prevState.searchInput, "searchInput", search, "search");
      this.setState({
        searchInput: search,
      });
      setTimeout(() => {
        this.postSearchBook(this.state.searchInput);
      }, 1000);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {
          // get all books
          if (apiRequestCallId === this.GetAllBooksApiCallId) {
            console.log("get all books ", responseJson.data);
            this.setState({
              allBooks: responseJson.data,
              Loader: false,
            });
          }

          // get my books
          if (apiRequestCallId === this.myBooksApiCallId) {
            console.log("my books ", responseJson.data);
            this.setState({
              myBooks: responseJson.data,
              Loader: false,
            });
          }

          // get all notes
          if (apiRequestCallId === this.getAllNotesApiCallId) {
            console.log("get all notes ", responseJson.data);
            this.setState({
              allNotes: responseJson.data,
              Loader: false,
            });
          }

          // get book detail
          if (apiRequestCallId === this.getBookDetailApiCallId) {
            console.log("get book detail ", responseJson.data);
            this.setState({
              bookDetail: responseJson.data,
              Loader: false,
            });
          }

          // search books
          if (apiRequestCallId === this.postSearchBookApiCallId) {
            console.log("search books ", responseJson.data);
            this.setState({
              searchedBooks: responseJson.data,
              Loader: false,
            });
          }
        }
      }
      // else {
      //   if (responseJson && responseJson.errors) {
      //     const errors = responseJson.errors[0] && responseJson.errors[0].token;
      //     const { history }: any = this.props;
      //     errors == "Token has Expired" && history.push("/");
      //   }
      // }
    }
  }

  // get all books
  getAllBooks = (): boolean => {
    this.setState({
      Loader: true,
    });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllBooksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllBooksAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //// get my books
  myBooks = (): boolean => {
    this.setState({
      Loader: true,
    });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.myBooksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetMyBooksAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //// get all notes
  getAllNotes = (): boolean => {
    this.setState({
      Loader: true,
    });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //// get book details
  getBookDetail = (): boolean => {
    this.setState({
      Loader: true,
    });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBookDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllBooksAPiEndPoint + `/${this.state.bookDetailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //search book

  postSearchBook = (search: any): boolean => {
    // const product = this.state.productToBeAdded

    this.setState({
      Loader: true,
    });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };

    const httpBody = {
      search_string: search,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSearchBookApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSearchBookAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area Start
  // Customizable Area End
}
