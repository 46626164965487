import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";

import StorageProvider from "../../../framework/src/StorageProvider.web";
// import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// Customizable Area End

import AdminConsoleController, {
  Props,
  configJSON,
} from "./AdminConsoleController";

import AdminDashboard from "./AdminDashboardWeb";
import PublishersList from "./PublishersListWeb";
import StudentsList from "./StudentsListWeb";
import UploadMedia from "./UploadMediaWeb";
import AddPublisher from "./AddPublisherWeb";
import EditPublisher from "./EditPublisherWeb";
import AllBooks from "./AllBooks.web";
import Header from "./Header.web";
import Subscriptions from "./Subscriptions.web";
import Notes from "./Notes.web";

class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState({ authToken });
    } else {
      const { history }: any = this.props;
      history.push("/");
    }
  }
  handleRoutes(route: any, state: any) {
    this.props.history.push({
      pathname: route,
      state: state,
    });
  }
  logout = () => {
    StorageProvider.remove("authToken");
    StorageProvider.remove("adminToken");
    StorageProvider.remove("publishserToken");
    this.handleRoutes("/", null);
  };
  // Customizable Area End

  render() {
    const { match }: any = this.props;
    return (
      <>
        <Header authToken={this.state.authToken} logout={this.logout} />
        <Switch>
          <Route
            path={`${match.path}/Students`}
            render={(routeProps) => (
              <StudentsList
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/Publishers`}
            render={(routeProps) => (
              <PublishersList
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/UploadMedia`}
            render={(routeProps) => (
              <UploadMedia
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/AddPublisher`}
            render={(routeProps) => (
              <AddPublisher
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/EditPublisher`}
            render={(routeProps) => (
              <EditPublisher
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/AllBooks`}
            render={(routeProps) => (
              <AllBooks
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/Notes`}
            render={(routeProps) => (
              <Notes
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}/Subscriptions`}
            render={(routeProps) => (
              <Subscriptions
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
          <Route
            path={`${match.path}`}
            render={(routeProps) => (
              <AdminDashboard
                {...routeProps}
                authToken={this.state.authToken}
                logout={this.logout}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withRouter(AdminConsole);
