import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import "../assets/css/notes.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
} from "./assets";
import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";

const styles = {
  cardRoot: {
    maxWidth: "400px",
    margin: "20px",
  },
} as any;
class MyNotes extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppHeader />
        <Loader loading={this.state.Loader} />

        <div className="catalog-book my-notes">
          <SideBar selectedIndex={2} />
          <div className="content">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link href="/Catalogue/Home" style={{ color: "#4caeef" }}>
                Home
              </Link>
              <Typography color="textPrimary">My Notes</Typography>
            </Breadcrumbs>

            <h3>My Notes</h3>
            <p className="color-gray">
              {(this.state.allNotes && this.state.allNotes.length) || 0} Books
              available
            </p>
            <div className="card-list-wrapper">
              <div className="left-part">
                <div className="card">
                  {this.state.allNotes &&
                    this.state.allNotes.length > 0 &&
                    this.state.allNotes.map((x: any, index: any) => {
                      return (
                        <div className="list">
                          <div className="list-inner">
                            <div className="img-part">
                              <img src={Published} />
                            </div>
                            <div className="text">
                              <strong>{x.attributes.name}</strong>
                              <span className="color-blue">
                                {x.attributes.note_count} Noted Available
                              </span>
                            </div>
                          </div>
                          <img
                            src={selectArrow}
                            alt="select arrow"
                            className="arrow"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(MyNotes);

// Customizable Area Start
// Customizable Area End
