'use strict';

// const baseURL = "https://kindlelikeapp-42900-ruby.42900.dev.ap-southeast-1.aws.svc.builder.cafe";
const baseURL =
  'https://kindlelikeapp-42900-ruby.b42900.dev.us-east-1.aws.svc.builder.cafe';

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;
