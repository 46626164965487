import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
} from "./assets";
import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";

const styles = {
  cardRoot: {
    maxWidth: "400px",
    margin: "20px",
  },
} as any;
class MyBooks extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppHeader />
        <Loader loading={this.state.Loader} />

        <div className="catalog-book">
          <SideBar selectedIndex={1} />
          <div className="content">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link href="/Catalogue/Home" style={{ color: "#4caeef" }}>
                Home
              </Link>
              <Typography color="textPrimary">My Books</Typography>
            </Breadcrumbs>
            <h3>My Books</h3>
            <p className="color-gray">
              {(this.state.myBooks && this.state.myBooks.length) || 0} Books
              available
            </p>

            <div className="card-list-wrapper">
              <div className="left-part">
                <div className="card">
                  {this.state.myBooks &&
                    this.state.myBooks.map((x: any, index: any) => {
                      return (
                        <>
                          <div className="list">
                            <img
                              src={x.attributes.cover_image_url}
                              alt="image"
                              onClick={() => {
                                //@ts-ignore
                                this.props.history.push(
                                  `/Catalogue/BookDetails/${x.attributes.id}`
                                );
                              }}
                            />
                            <div className="img-part" />

                            <div className="text">
                              <strong>{x.attributes.name}</strong>
                              <small className="color-gray">
                                {x.attributes.book_type}
                              </small>
                              <div className="details">
                                <div>
                                  <span>{x.attributes.author}</span>
                                  <span className="color-blue">
                                    {x.attributes.note_count} Noted Available
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* <img src={} alt="play" /> */}
                          </div>
                          <div className="more">
                            <button className="btn" />
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="right-part">
                <strong className="title">Book Purchase history</strong>

                <div className="block-details">
                  <span className="tag-line">Recturting per screen</span>
                  <div className="list">
                    {/* <img src={} alt="image" /> */}
                    <div className="img-part" />

                    <div className="text">
                      <strong>The world of Abstract Act</strong>
                      <small className="color-gray">Thriler</small>
                      <div className="details">
                        <div>
                          <span>Emaily Robbins</span>
                          <span className="color-blue">3 Noted Available</span>
                        </div>
                      </div>
                    </div>

                    {/* <img src={} alt="play" /> */}
                  </div>

                  <ul>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="block-details">
                  <span className="tag-line">Recturting per screen</span>
                  <div className="list">
                    {/* <img src={} alt="image" /> */}
                    <div className="img-part" />

                    <div className="text">
                      <strong>The world of Abstract Act</strong>
                      <small className="color-gray">Thriler</small>
                      <div className="details">
                        <div>
                          <span>Emaily Robbins</span>
                          <span className="color-blue">3 Noted Available</span>
                        </div>
                      </div>
                    </div>

                    {/* <img src={} alt="play" /> */}
                  </div>

                  <ul>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                    <li>
                      {/*master card image here*}
                                                {/* <img src={} alt="master-card"  className="master-card"/> */}
                      <div className="discription-part">
                        <p>customer Name </p>
                        <strong>
                          000 000 <span className="color-gray">426</span>
                        </strong>
                      </div>
                      <div className="price-part">
                        <p>20/7/21</p>
                        <strong>$25</strong>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
//@ts-ignore
const MyBooksRouter = withRouter(MyBooks);
export default withStyles(styles)(MyBooksRouter);

// Customizable Area Start
// Customizable Area End
