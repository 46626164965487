export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CloseIcon = require("../assets/Close_Icon.png");
export const pinkDownload = require("../assets/ICON Downloads.png");
export const UploadImage = require("../assets/Upload.png");
export const pdfIcon = require("../assets/group_PDF Icon.png");
export const musicIcon = require("../assets/view_Music Icon.png");
export const searchImage = require("../assets/Search.png");
export const threeDot = require("../assets/image_Overflowmenu Icon.png");
export const EditIcon = require("../assets/Edit_Icon.png");
export const BlockIcon = require("../assets/ic_password_invisible.png");
export const DeleteIcon = require("../assets/Delete_Icon.png");
export const dollor = require("../assets/Earnings_Icon.png");
export const blueEyes = require("../assets/Viewers Icon.png");
export const subscriptionIcon = require("../assets/Group_Subscription.svg");
