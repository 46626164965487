import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  // Button,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/Visibility";
import { Link } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { closeIcon } from "./assets";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  fbIcon,
  googleIcon,
  peddlerImage,
  sliderImage,
  background,
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import SocialMediaLogin from "../../social-media-account-login/src/SocialMediaAccountLoginScreen.web";
//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";

import { RouteComponentProps, withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import SocialMediaAccountLoginScreen from "../../social-media-account-login/src/SocialMediaAccountLoginScreen.web";

// import { Link } from 'react-router-dom';
// Customizable Area End
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginControllerWeb";
import { sign } from "crypto";

const styles = {
  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${background})`,
    // backgroundColor:"blue"
  },
  firstCard: {
    height: "auto",
    width: "auto",
    backgroundColor: "white",
    borderRadius: "25px",
    border: "1px solid #f5f6fa",
    boxShadow: "-7px 15px 0px 15px #215478",
    padding: "30px",
  },
  secondCard: {
    height: "auto",
    width: "auto",
    backgroundColor: "white",
    borderRadius: "25px",
    border: "2px solid #f5f6fa",
    padding: "20px",
    boxShadow: "11px 18px 0px 11px #215478",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
  },
  pedlerImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
  sliceImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
    marginBottom: "10%",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column"
  },
  title2: {
    display: "flex",
    justifyContent: "center",
  },
  singIn: {
    display: "flex",
    flexDirection: "space-between",
    // marginRight:"30px"
    // flexDirection:"row"
  },
  button: {
    backgroundColor: "#4caeef",
    color: "white",
  },
  box: {
    height: "40px",
    width: "40px",
    backgroundColor: "#4caeef",
    borderRadius: "8px",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  confirmInput: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    borderRadius: "8px",
    // backgroundColor:"white",
    height: "200px",
    border: "1px solid #e4e8f0",
  },
  root: {
    minWidth: "400px",
    borderRadius: "8px",
    boxShadow:
      " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
  // forgotPassword: {
  //     fontSize: 32,
  //     fontFamily: "Inter",
  //     marginTop: 0,
  //     fontWeight: 600
  // },

  circle: {
    background: "white",
    borderRadius: "50%",
    marginTop: "15px",
    width: " 25px",
    height: "25px",
    display: "flex" /* or inline-flex */,
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
} as any;
class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleClickOpen = () => {
    console.log("gjg");
    this.setState({
      setOpen: true,
    });
  };
  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };
  render() {
    const { navigation, classes } = this.props;
    return (
      // Required for all blocks

      <div className={classes.mainDiv}>
        <div className={classes.cards}>
          <Backdrop
            className={classes.backdrop}
            open={this.state.open}
            onClick={(e) => this.handleCloseLoader()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={classes.firstCard}>
            <div className={classes.pedlerImage}>
              <img
                src={peddlerImage}
                style={{ height: "50px", width: "50px" }}
              />
            </div>
            <div className={classes.sliceImage}>
              <img
                src={sliderImage}
                style={{ height: "150px", width: "200px" }}
              />
            </div>
            <div className={classes.title}>
              <p>Welcome to Peddler</p>
            </div>
            <div className={classes.title2}>
              <p>study how you want, where you want</p>
            </div>
          </div>
          <div className={classes.secondCard}>
            <div className={classes.singIn}>
              <p style={{ marginRight: "40px" }}>Welcome to Peddler</p>
              {/* <div className={classes.box}>
                <img src={fbIcon} style={{ height: "20px", width: "20px" }} />
              </div>
              <div className={classes.box}>
                <img
                  src={googleIcon}
                  style={{ height: "20px", width: "20px" }}
                />
              </div> */}
              <SocialMediaAccountLoginScreen />
            </div>

            <div style={{ marginTop: "20%" }}>
              <Formik
                initialValues={{
                  email: "",
                  // animal: "",
                  password: "",

                  // confirmPassword:'',
                  // confirmPassword: "",
                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  email: Yup.string().required("Email is required"),
                  // .email("Invalid email address")
                  // .required("Please enter an email address"),
                  password: Yup.string().required("Password is required "),
                })}
                onSubmit={(values) => {
                  console.log(values);
                  this.doEmailLogIn(values);
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="email"
                      type="email"
                      size="small"
                      // fullWidth
                      // style={{width:"400px"}}
                      className={classes.confirmInput}
                      label="Email"
                      value={values.email}
                      variant="outlined"
                      helperText={
                        <span style={{ color: "red" }}>{errors.email}</span>
                      }
                      error={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="password"
                      type="password"
                      size="small"
                      style={{ display: "block" }}
                      // fullWidth
                      // style={{width:"400px"}}
                      // style={{width:"300px"}}
                      // style={{width:"400px"}}
                      label="Password"
                      value={values.password}
                      variant="outlined"
                      className={classes.confirmInput}
                      helperText={
                        <span style={{ color: "red" }}>{errors.password}</span>
                      }
                      error={errors.password && touched.password ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div>
                      <div className={classes.forgotPassword}>
                        <p
                          style={{ color: "#4caeef" }}
                          onClick={this.handleClickOpen}
                        >
                          Forgot password
                        </p>
                      </div>
                      <div className={classes.forgotPassword}>
                        <Button
                          className={classes.button}
                          type="submit"
                          fullWidth
                          variant="contained"
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <p onClick={this.goToEmailAccountRegistration}>
                Don't have an account?{" "}
                <Link style={{ color: "#4caeef" }}>create one</Link>
              </p>
            </div>
          </div>
        </div>

        {/* forgot password modal */}
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.setOpen}
        >
          <DialogTitle id="customized-dialog-title" style={{ height: "35px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p className={classes.forgotPassword}> Forgot password</p>
              <div className={classes.circle}>
                <img
                  src={closeIcon}
                  style={{ height: "15px", width: "15px" }}
                  onClick={this.handleClose}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent style={{ minWidth: "400px" }}>
            <div style={{ marginTop: "20px" }}>
              <Formik
                initialValues={{
                  email: "",
                }}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  // email: Yup.string()
                  // .email("Invalid email address")
                  // .required("Please enter an email address"),
                  email: Yup.string().required("Email is required"),
                })}
                onSubmit={(values) => {
                  console.log(values);
                  this.doForgotPassword(values);
                  // this.goToConfirmationAfterPasswordChange(values);
                  // this.props.nextStep(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  isValid,
                  handleBlur,
                  setFieldTouched,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="email"
                      type="email"
                      size="small"
                      fullWidth
                      label="Email"
                      value={values.email}
                      variant="outlined"
                      helperText={
                        // errors.confirmPassword && touched.confirmPassword
                        // ? errors.confirmPassword
                        // : 'Password must have 8 characters'

                        // errors.password && touched.password ? (
                        // <Trans>
                        // NewPasswordController.mustHavePassword
                        // </Trans>
                        // ) : (
                        // <Trans>
                        // NewPasswordController.passwordIsRequired
                        // </Trans>
                        // )
                        <span style={{ color: "red" }}>{errors.email}</span>
                      }
                      // error={
                      // touched.confirmPassword && errors.confirmPassword
                      // ? true
                      // : false
                      // }
                      error={errors.email && touched.email ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <CardActions style={{ justifyContent: "center" }}>
                      <Button
                        className={classes.button}
                        // color="primary"
                        type="submit"
                        variant="contained"
                      >
                        CONFIRM EMAIL
                      </Button>
                    </CardActions>
                  </form>
                )}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const EmailAccountLoginWithRouter = withRouter(EmailAccountLoginBlock);
const EmailAccountLoginWithToast = withToast(EmailAccountLoginWithRouter);
const EmailAccountLoginWithLoader = withLoader(EmailAccountLoginWithToast);
const EmailAccountLoginWithAlertBox = withAlertBox(EmailAccountLoginWithLoader);
export default withStyles(styles)(EmailAccountLoginWithAlertBox);
// Customizable Area Start

// Customizable Area End
