import React, { useState } from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HomeIcon from "@material-ui/icons/Home";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "../assets/css/sidebar.css";
import { withRouter, Link } from "react-router-dom";
import {
  BrowserRouter,
  Router,
  Route,
  NavLink,
  Switch,
  HashRouter,
} from "react-router-dom";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CatalogueController, { Props } from "./CatalogueController";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import Home from "./Home.web";
import MyBook from "./MyBooks.web";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  downloadsActive,
  Notes,
  NotesActive,
  earnings,
  earningsActive,
  subscription,
  subscriptionActive,
  paymentHistory,
  purchasedNotes,
  purchasedNotesActive,
  paymentActive,
  paymentInactive,
} from "./assets";

const styles = {
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },

  search: {
    position: "relative",
    background: "#5196DD",
    // border:"1px solid red",
    // backgroundCo #4CAEEF
    color: "white",
    //   backgroundColor:"rgb(68, 156, 214)",
    //   marginLeft:"0",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    width: "100%",
    padding: "13px 50px",
  },
  notesForSale: {
    backgroundColor: "#F2F3F2",
    height: "auto",
    margin: "20px",
    borderRadius: "8px",
  },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    // margin: "23px",

    minWidth: 120,
  },
} as any;

const SideBar = (props: any) => {
  const { classes } = props;
  const [toggle, setToggle] = useState(true);
  const selectedIndex = props.selectedIndex;
  const handleListItemClick = (event: any, index: any, str?: any) => {
    setToggle(true);
  };
  return (
    //Merge Engine DefaultContainer
    //<BrowserRouter>
    <>
      {/* <BrowserRouter> */}

      <div className="sidebar">
        {/* <Grid container spacing={0}> */}
        {/* <Grid item xs={2}> */}
        <div
          className="toggle-button-wrap"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <MenuRoundedIcon />
          {/* <button className="toggle-button" /> */}
        </div>
        <div
          className={toggle ? "mobile-view-sidebar" : ""}
          style={{
            backgroundColor: "#F2F3F2",
            height: "100vh",
            borderRadius: "8px",
            marginLeft: "4px",
          }}
        >
          <List component="nav" aria-label="main mailbox folders">
            <NavLink
              exact
              to="/Catalogue/Home"
              style={{ textDecoration: "none" }}
            >
              <ListItem
                button
                selected={selectedIndex == 0}
                onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  className={selectedIndex == 0 ? "active-sidebar" : ""}
                >
                  {selectedIndex == 0 ? (
                    <HomeIcon className="active-sidebar" />
                  ) : (
                    <HomeOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Home"
                  className={selectedIndex == 0 ? "active-sidebar" : ""}
                />
              </ListItem>
            </NavLink>
            <NavLink to="/Catalogue/MyBooks" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemIcon>
                  <img
                    src={selectedIndex == 1 ? downloadsActive : downloads}
                    style={{ height: "20px", width: "25px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="My Books"
                  className={selectedIndex == 1 ? "active-sidebar" : ""}
                />
              </ListItem>
            </NavLink>

            <NavLink to="/Catalogue/MyNotes" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemIcon>
                  <img
                    src={selectedIndex == 2 ? NotesActive : Notes}
                    style={{ height: "20px", width: "25px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="My Notes"
                  className={selectedIndex == 2 ? "active-sidebar" : ""}
                />
              </ListItem>
            </NavLink>
            <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon>
                <img
                  src={
                    selectedIndex == 3 ? purchasedNotesActive : purchasedNotes
                  }
                  style={{ height: "20px", width: "25px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Purchased Notes"
                className={selectedIndex == 3 ? "active-sidebar" : ""}
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              <ListItemIcon>
                <img
                  src={selectedIndex === 4 ? earningsActive : earnings}
                  style={{ height: "20px", width: "25px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="My Earnings"
                className={selectedIndex == 4 ? "active-sidebar" : ""}
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemIcon>
                <img
                  src={selectedIndex === 5 ? paymentActive : paymentInactive}
                  style={{ height: "20px", width: "25px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Payment History"
                className={selectedIndex == 5 ? "active-sidebar" : ""}
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              <ListItemIcon>
                <img
                  src={selectedIndex === 6 ? subscriptionActive : subscription}
                  style={{ height: "20px", width: "25px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="My Packages"
                className={selectedIndex == 6 ? "active-sidebar" : ""}
              />
            </ListItem>
          </List>
        </div>
        {/* </Grid> */}
        {/* <Grid item xs={9}>
            <Switch>
              <Route exact path="/Catalogue/Home" component={Home} />
              <Route exact path="/Catalogue/MyBooks" component={MyBook} />
            </Switch>
          </Grid> */}
        {/* </Grid> */}
      </div>
    </>
  );
};

//@ts-ignore
export default withStyles(styles)(SideBar);

// Customizable Area Start
// Customizable Area End
